import './css/main.css';
import React, { lazy, Suspense } from 'react';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from 'react-router-dom';

import { HelmetProvider } from 'react-helmet-async';

import FallbackLoader from './Components/FallbackLoader/FallbackLoader';
import ScrollToTop from './Components/ScrollToTop';

const Menu = lazy(() => import('./Components/Menu/Menu'));
const Home = lazy(() => import('./Navigation/Home/Home'));
const AlertMessage = lazy(() =>
	import('./Components/AlertMessage/AlertMessage')
);

const ImageGrid = lazy(() =>
	import('./Navigation/Courses/AllCourses/ImageGrid/ImageGrid')
);

const SingleCoursePage = lazy(() =>
	import('./Navigation/Courses/FullCoursePage/SingleCoursePage')
);

const AllCoursesPage = lazy(() =>
	import('./Navigation/Courses/AllCourses/AllCoursesPage')
);
const Cases = lazy(() => import('./Navigation/Cases/Cases'));
const Blog = lazy(() => import('./Navigation/Blog/Blog'));
const AboutUs = lazy(() => import('./Navigation/AboutUs/AboutUs'));
const Career = lazy(() => import('./Navigation/Career/Career'));
const Services = lazy(() => import('./Navigation/Services/Services'));
// const Menu = lazy(() => import('./Components/Menu/Menu'));
const Footer = lazy(() => import('./Components/Footer/Footer'));
const BlogPost = lazy(() => import('./Navigation/Blog/BlogPost/BlogPost'));
const Admin = lazy(() => import('./Navigation/Admin/Admin'));

const FirestoreContextProvider = lazy(() =>
	import('./contexts/FirestoreContext')
);
const SingleJob = lazy(() => import('./Navigation/Career/SingleJob'));
const ViewCase = lazy(() => import('./Navigation/Cases/ViewCase/ViewCase'));

function App() {
	return (
		<>
			<HelmetProvider>
				<Router>
					<ScrollToTop />

					<Suspense
						fallback={<div className="menu-placeholder fixed-top"></div>}
					>
						<Menu />
					</Suspense>
					<Suspense fallback={<FallbackLoader />}>
						<div className="root-container pt-5">
							<FirestoreContextProvider>
								<AlertMessage />

								<Routes>
									<Route exact path="/" element={<Home />} />
									<Route exact path="/admin" element={<Admin />} />
									<Route exact path="/about-us" element={<AboutUs />} />
									<Route exact path="/career" element={<Career />} />
									<Route exact path="/cases" element={<Cases />} />
									<Route exact path="/career/:id" element={<SingleJob />} />
									<Route exact path="/services" element={<Services />} />

									<Route exact path="/training" element={<AllCoursesPage />} />
									<Route
										exact
										path="/training/gallery"
										element={<ImageGrid />}
									/>

									<Route
										path="/training/:courseId"
										element={<SingleCoursePage />}
									/>

									<Route exact path="/blog" element={<Blog />} />
									<Route path="/blog/:blogId" element={<BlogPost />} />
									<Route path="/cases/:caseId" element={<ViewCase />} />

									<Route path="*" element={<Navigate to="/" replace />} />
								</Routes>
							</FirestoreContextProvider>
						</div>
						<Footer />
					</Suspense>
				</Router>
			</HelmetProvider>
		</>
	);
}

export default App;
